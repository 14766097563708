
import Vue from 'vue';
import validationRules from '@/validation-rules';
import { ExportToCsv } from 'export-to-csv';
import Chart from 'chart.js';
import { format } from 'date-fns';
import SitchOrderItemBreakdown from '@/components/custom-ui-components/SitchOrderItemBreakdown.vue';
import { ZERO_DECIMAL_CURRENCIES, getEmptyGlobalPaymentRecord, endpoints } from '@/constants';
import { eOrderStatuses, eThemes } from '@/enums';
import { acceptedEmailBody, readyForPickUpEmailBody, deliveredEmailBody, dispatchedEmailBody, rejectedEmailBody, onHoldEmailBody } from '@/email-templates/preMadeEmails';
import { collection, doc, limit, onSnapshot, orderBy, query, updateDoc, where } from 'firebase/firestore';
import { isProductionEnv, currFirestore, standardApiFetch } from '@/util-functions/initialization-utils';
import { t } from '@/util-functions/language-utils';
import { showLoading, hideLoading } from '@/util-functions/loading-utils';
import { showError } from '@/util-functions/misc-firestore-utils';
import { getCustomFieldValue, getOrderLink, enforceMinMax, formatCurrency } from '@/util-functions/misc-utils';
import { showNotice, showConfirmation } from '@/util-functions/notice-utils';
import { getStripePublicKey } from '@/util-functions/stripe-utils';
import { saveSettings, getUserDoc } from '@/util-functions/user-utils';
import { copyText } from '@/util-functions/user-utils';

export default Vue.extend({
  components: {
    SitchOrderItemBreakdown,
  },
  data(): {
    headers: TableHeaders;
    currPayment: GlobalPaymentRecord | null;
    totalsForToday: { [currencyCode: string]: number };
    totalTipsForToday: { [currencyCode: string]: number };
    totalRevenueBeforeTaxChargedForPeriod: { [currencyCode: string]: number };
    totalRevenueAfterTaxChargedForPeriod: { [currencyCode: string]: number };
    totalTaxChargedForPeriod: { [currencyCode: string]: number };
    totalTipsForPeriod: { [currencyCode: string]: number };
    formattedTotalsForToday: { [currencyCode: string]: string };
    formattedTotalTipsForToday: { [currencyCode: string]: string };
    formattedTotalRevenueBeforeTaxChargedForPeriod: { [currencyCode: string]: string };
    formattedTotalRevenueAfterTaxChargedForPeriod: { [currencyCode: string]: string };
    formattedTotalTaxChargedForPeriod: { [currencyCode: string]: string };
    formattedTotalTipsForPeriod: { [currencyCode: string]: string };
    showPaymentDetailsDialog: boolean;
    showRefundDialog: boolean;
    stripeClient: any;
    stripeUrlSlug: string;
    page: number;
    maximumRefundAmount: number;
    refundAmount: number;
    rules: typeof validationRules;
    stripeAccount: StripeAccount | null;
    dateRangeMenu: boolean;
    dateRange: string[];
    periodSpecified: boolean;
    eOrderStatuses: typeof eOrderStatuses;
    orderStatusItems: TextValue[];
    showExportDialog: boolean;
    exportId: boolean;
    exportReferenceId: boolean;
    exportOrderStatus: boolean;
    exportFormattedBaseAmount: boolean;
    exportFormattedTipAmount: boolean;
    exportFormattedTaxAmount: boolean;
    exportFormattedTotalAmount: boolean;
    exportDate: boolean;
    exportCustomersName: boolean;
    exportEmail: boolean;
    exportDeliveryAddress: boolean;
    exportPromoCodesUsed: boolean;
    exportOrderBreakdown: boolean;
    exportOrderLink: boolean;
    accountWasJustLoaded: boolean;
    mostRecentPayment: GlobalPaymentRecord | null;
    watchedCollection: (() => void) | null;
    paymentRecords: GlobalPaymentRecord[];
    statusFilter: eOrderStatuses | null;
    statusFilters: {
      text: string;
      value: eOrderStatuses | null;
    }[];
    totalsForTodayExists: boolean;
    totalTipsForTodayExists: boolean;
    totalRevenueBeforeTaxChargedForPeriodExists: boolean;
    totalRevenueAfterTaxChargedForPeriodExists: boolean;
    totalTaxChargedForPeriodExists: boolean;
    totalTipsForPeriodExists: boolean;
    tableSearchTerm: string;
  } {
    const headers = [
      { text: t.receivedAt, value: 'dateCreated' },
      { text: t.paidBy, value: 'personWhoPaidName' },
      { text: t.amount, value: 'formattedTotalAmount' },
      { text: t.orderStatus, value: 'orderStatus' },
      { text: t.actions, value: '', sortable: false, align: 'center' },
    ];

    return {
      headers,
      currPayment: null,
      totalsForToday: {},
      totalTipsForToday: {},
      totalRevenueBeforeTaxChargedForPeriod: {},
      totalRevenueAfterTaxChargedForPeriod: {},
      totalTaxChargedForPeriod: {},
      totalTipsForPeriod: {},
      formattedTotalsForToday: {},
      formattedTotalTipsForToday: {},
      formattedTotalRevenueBeforeTaxChargedForPeriod: {},
      formattedTotalRevenueAfterTaxChargedForPeriod: {},
      formattedTotalTaxChargedForPeriod: {},
      formattedTotalTipsForPeriod: {},
      showPaymentDetailsDialog: false,
      showRefundDialog: false,
      stripeClient: null,
      stripeUrlSlug: isProductionEnv ? '' : 'test/',
      page: 1,
      maximumRefundAmount: 0,
      refundAmount: 0,
      rules: validationRules,
      stripeAccount: null,
      dateRangeMenu: false,
      dateRange: [],
      periodSpecified: false,
      eOrderStatuses,
      orderStatusItems: Object.values(eOrderStatuses).map((orderStatus: string) => ({
        text: t[orderStatus],
        value: orderStatus,
      })),
      showExportDialog: false,
      exportId: true,
      exportReferenceId: true,
      exportOrderStatus: true,
      exportFormattedBaseAmount: true,
      exportFormattedTipAmount: true,
      exportFormattedTaxAmount: true,
      exportFormattedTotalAmount: true,
      exportDate: true,
      exportCustomersName: true,
      exportEmail: true,
      exportDeliveryAddress: true,
      exportPromoCodesUsed: true,
      exportOrderBreakdown: true,
      exportOrderLink: true,
      accountWasJustLoaded: false,
      mostRecentPayment: null,
      watchedCollection: null,
      paymentRecords: [],
      statusFilter: null,
      statusFilters: [
        {
          text: t.all,
          value: null,
        },
        {
          text: t[eOrderStatuses.noStatus],
          value: eOrderStatuses.noStatus,
        },
        {
          text: t[eOrderStatuses.onHold],
          value: eOrderStatuses.onHold,
        },
        {
          text: t[eOrderStatuses.accepted],
          value: eOrderStatuses.accepted,
        },
        {
          text: t[eOrderStatuses.rejected],
          value: eOrderStatuses.rejected,
        },
        {
          text: t[eOrderStatuses.completed],
          value: eOrderStatuses.completed,
        },
        {
          text: t[eOrderStatuses.dispatched],
          value: eOrderStatuses.dispatched,
        },
        {
          text: t[eOrderStatuses.delivered],
          value: eOrderStatuses.delivered,
        },
        {
          text: t[eOrderStatuses.readyForPickUp],
          value: eOrderStatuses.readyForPickUp,
        },
        {
          text: t[eOrderStatuses.pickedUp],
          value: eOrderStatuses.pickedUp,
        },
      ],
      totalsForTodayExists: false,
      totalTipsForTodayExists: false,
      totalRevenueBeforeTaxChargedForPeriodExists: false,
      totalRevenueAfterTaxChargedForPeriodExists: false,
      totalTaxChargedForPeriodExists: false,
      totalTipsForPeriodExists: false,
      tableSearchTerm: '',
    };
  },
  mounted() {
    if (this.$store.state.appTheme === eThemes.dark) {
      Chart.defaults.global.defaultFontColor = '#fff';
    } else {
      Chart.defaults.global.defaultFontColor = '#000';
    }
  },
  computed: {
    showNewItemAlert: {
      get(): boolean {
        return this.$store.state.currUser.settings.showNewPaymentAlert;
      },
      set(val: boolean) {
        saveSettings({ showNewPaymentAlert: val });
      },
    },
    dateRangeText(): string {
      return this.dateRange.join(' ~ ');
    },
    stripeAccounts(): StripeAccount[] {
      return this.$store.getters.stripeAccounts;
    },
    orderBreakdown(): OrderItem[] {
      return this.currPayment?.orderBreakdown || [];
    },
    buildMailToHrefForEmailNotification(): string {
      let subject = '';
      let body = '';

      if (!this.stripeAccount) {
        showError(`No Stripe account available`, null, true);
        return '';
      }

      switch (this.currPayment?.orderStatus) {
        case eOrderStatuses.accepted:
          {
            const emailObj = acceptedEmailBody(this.currPayment, this.stripeAccount);
            subject = emailObj.subject;
            body = emailObj.body;
          }
          break;
        case eOrderStatuses.rejected:
          {
            const emailObj = rejectedEmailBody(this.currPayment, this.stripeAccount);
            subject = emailObj.subject;
            body = emailObj.body;
          }
          break;
        case eOrderStatuses.dispatched:
          {
            const emailObj = dispatchedEmailBody(this.currPayment, this.stripeAccount);
            subject = emailObj.subject;
            body = emailObj.body;
          }
          break;
        case eOrderStatuses.delivered:
          {
            const emailObj = deliveredEmailBody(this.currPayment, this.stripeAccount);
            subject = emailObj.subject;
            body = emailObj.body;
          }
          break;
        case eOrderStatuses.readyForPickUp:
          {
            const emailObj = readyForPickUpEmailBody(this.currPayment, this.stripeAccount);
            subject = emailObj.subject;
            body = emailObj.body;
          }
          break;
        case eOrderStatuses.onHold:
          {
            const emailObj = onHoldEmailBody(this.currPayment, this.stripeAccount);
            subject = emailObj.subject;
            body = emailObj.body;
          }
          break;
      }
      return `mailto:${this.currPayment?.personWhoPaidEmail}?subject=${subject}&body=${body}`;
    },
  },
  watch: {
    dateRange() {
      if (this.dateRange.length !== 1) {
        this.getPaymentsForSelectedAccount();
      }
    },
    stripeAccount() {
      if (this.stripeAccount) {
        this.stripeClient = (window as any).Stripe(getStripePublicKey(), {
          stripeAccount: this.stripeAccount.stripeUserId,
        });
        this.getPaymentsForSelectedAccount();
      }
    },
  },
  beforeDestroy() {
    if (this.watchedCollection) {
      this.watchedCollection(); // Detach old listenter if it exists
    }
  },
  methods: {
    getCustomFieldValue(customFieldData: CustomFieldData, forCsv = false) {
      getCustomFieldValue(customFieldData, forCsv);
    },
    copyText(text: string) {
      copyText(text);
    },
    showEmailBtnForStatus(status: eOrderStatuses): boolean {
      return [eOrderStatuses.accepted, eOrderStatuses.rejected, eOrderStatuses.dispatched, eOrderStatuses.delivered, eOrderStatuses.readyForPickUp, eOrderStatuses.onHold].includes(status);
    },
    csvOptions() {
      return {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: true,
        title: t.exportCsvRecordsTitle.supplant([this.stripeAccount?.displayName]),
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
      };
    },
    exportForShippo() {
      const csvOptions = this.csvOptions();
      csvOptions.showTitle = false;

      const csvExporter = new ExportToCsv(csvOptions);

      csvExporter.generateCsv(
        this.paymentRecords
          .filter((rec) => {
            return !this.statusFilter || rec.orderStatus === this.statusFilter;
          })
          .map((paymentRecord) => {
            const returnObj: any = {};
            const currency = paymentRecord.currency.toUpperCase();
            const mergedStreetAddress =
              paymentRecord.deliveryAddress?.address?.streetNumber && paymentRecord.deliveryAddress?.address?.route
                ? `${paymentRecord.deliveryAddress?.address?.streetNumber} ${paymentRecord.deliveryAddress?.address?.route}`
                : paymentRecord.deliveryAddress?.address?.route;

            returnObj['Order Number'] = paymentRecord.referenceId;
            returnObj['Order Date'] = format(paymentRecord.dateCreated.toDate(), 'yy-MM-dd');
            returnObj['Recipient Name'] = paymentRecord.personWhoPaidName;
            returnObj['Email'] = paymentRecord.personWhoPaidEmail;
            returnObj['Phone'] = paymentRecord.deliveryAddress?.phoneNumber || '';
            returnObj['Street Number'] = paymentRecord.deliveryAddress?.address?.streetNumber;
            returnObj['Street Line 1'] = paymentRecord.deliveryAddress?.address?.streetAddress || mergedStreetAddress || '';
            returnObj['Street Line 2'] = paymentRecord.deliveryAddress?.addressLine2;
            returnObj['City'] = paymentRecord.deliveryAddress?.address?.administrativeAreaLevel2 || paymentRecord.deliveryAddress?.address?.locality;
            returnObj['State/Province'] = paymentRecord.deliveryAddress?.address?.administrativeAreaLevel1;
            returnObj['Zip/Postal Code'] = paymentRecord.deliveryAddress?.address?.postalCode;
            returnObj['Country'] = paymentRecord.deliveryAddress?.address?.country;
            returnObj['Item Title'] = paymentRecord.orderBreakdown.map((order) => `${order.name} x${order.quantity}`).join(' | ');
            returnObj['Quantity'] = 1;
            if (this.stripeAccount?.displayName === 'sitch.cards') {
              returnObj['Order Weight'] = 100;
              returnObj['Order Weight Unit'] = 'g';
            }
            returnObj['Order Amount'] = ZERO_DECIMAL_CURRENCIES.includes(currency) ? paymentRecord.totalAmount : (paymentRecord.totalAmount / 100).toFixed(2);
            returnObj['Order Currency'] = paymentRecord.currency.toUpperCase();

            return returnObj;
          })
      );
    },
    exportForChitChats2() {
      const csvOptions = this.csvOptions();
      csvOptions.showTitle = false;

      const csvExporter = new ExportToCsv(csvOptions);

      csvExporter.generateCsv(
        this.paymentRecords
          .filter((rec) => {
            return !this.statusFilter || rec.orderStatus === this.statusFilter;
          })
          .map((paymentRecord) => {
            const returnObj: any = {};
            const currency = paymentRecord.currency.toUpperCase();
            const mergedStreetAddress =
              paymentRecord.deliveryAddress?.address?.streetNumber && paymentRecord.deliveryAddress?.address?.route
                ? `${paymentRecord.deliveryAddress?.address?.streetNumber} ${paymentRecord.deliveryAddress?.address?.route}`
                : paymentRecord.deliveryAddress?.address?.route;

            returnObj['name'] = paymentRecord.personWhoPaidName;
            returnObj['address_1'] = paymentRecord.deliveryAddress?.address?.streetAddress || mergedStreetAddress || '';
            returnObj['address_2'] = paymentRecord.deliveryAddress?.addressLine2;
            returnObj['city'] = paymentRecord.deliveryAddress?.address?.administrativeAreaLevel2 || paymentRecord.deliveryAddress?.address?.locality;
            returnObj['province_code'] = paymentRecord.deliveryAddress?.address?.administrativeAreaLevel1;
            returnObj['postal_code'] = paymentRecord.deliveryAddress?.address?.postalCode;
            returnObj['country_code'] = paymentRecord.deliveryAddress?.address?.country?.toLocaleLowerCase() === 'usa' ? 'US' : paymentRecord.deliveryAddress?.address?.country;
            returnObj['phone'] = paymentRecord.deliveryAddress?.phoneNumber || '';
            returnObj['description'] = paymentRecord.orderBreakdown.map((order) => `${order.name} x${order.quantity}`).join(' | ');
            returnObj['value'] = ZERO_DECIMAL_CURRENCIES.includes(currency) ? paymentRecord.totalAmount : (paymentRecord.totalAmount / 100).toFixed(2);
            returnObj['value_currency'] = paymentRecord.currency.toUpperCase();
            returnObj['order_id'] = paymentRecord.referenceId;
            if (this.stripeAccount?.displayName === 'sitch.cards') {
              returnObj['size_x'] = 8;
              returnObj['size_y'] = 4;
              returnObj['size_z'] = 0.5;
              returnObj['size_unit'] = 'in';
              returnObj['weight'] = 100;
              returnObj['weight_unit'] = 'g';
              returnObj['package_type'] = 'thick_envelope';
              returnObj['insurance_requested'] = 'no';
            }
            returnObj['ship_date'] = 'today';
            return returnObj;
          })
      );
    },
    exportForChitChats() {
      const csvOptions = this.csvOptions();
      csvOptions.showTitle = false;

      const csvExporter = new ExportToCsv(csvOptions);

      csvExporter.generateCsv(
        this.paymentRecords
          .filter((rec) => {
            return !this.statusFilter || rec.orderStatus === this.statusFilter;
          })
          .flatMap((paymentRecord) => {
            return paymentRecord.orderBreakdown.map((orderItem) => {
              const returnObj: any = {};
              const currency = paymentRecord.currency.toUpperCase();
              const mergedStreetAddress =
                paymentRecord.deliveryAddress?.address?.streetNumber && paymentRecord.deliveryAddress?.address?.route
                  ? `${paymentRecord.deliveryAddress?.address?.streetNumber} ${paymentRecord.deliveryAddress?.address?.route}`
                  : paymentRecord.deliveryAddress?.address?.route;

              returnObj['name'] = paymentRecord.personWhoPaidName;
              returnObj['address_1'] = paymentRecord.deliveryAddress?.address?.streetAddress || mergedStreetAddress || '';
              returnObj['address_2'] = paymentRecord.deliveryAddress?.addressLine2;
              returnObj['city'] = paymentRecord.deliveryAddress?.address?.administrativeAreaLevel2 || paymentRecord.deliveryAddress?.address?.locality;
              returnObj['province_code'] = paymentRecord.deliveryAddress?.address?.administrativeAreaLevel1;
              returnObj['postal_code'] = paymentRecord.deliveryAddress?.address?.postalCode;
              returnObj['country_code'] = ['usa', 'america', 'united states'].includes(paymentRecord.deliveryAddress?.address?.country?.toLocaleLowerCase() || '')
                ? 'US'
                : paymentRecord.deliveryAddress?.address?.country.toUpperCase();
              returnObj['phone'] = paymentRecord.deliveryAddress?.phoneNumber || '';
              returnObj['item_description'] = orderItem.name;
              returnObj['item_quantity'] = orderItem.quantity;
              returnObj['item_unit_value'] = ZERO_DECIMAL_CURRENCIES.includes(currency) ? orderItem.totalAmountWithTax : (orderItem.totalAmountWithTax / 100).toFixed(2);
              returnObj['value_currency'] = paymentRecord.currency.toUpperCase();
              returnObj['order_id'] = paymentRecord.referenceId;
              if (this.stripeAccount?.displayName === 'sitch.cards') {
                returnObj['size_x'] = 8;
                returnObj['size_y'] = 4;
                returnObj['size_z'] = 0.5;
                returnObj['weight'] = 100;
                returnObj['item_hs_code'] = '4911.99.80.00'; // Sitch Card.
                // If an item in the order is a band or the quantity of some part of the order is larger than 10 units.
                if (paymentRecord.orderBreakdown.some((order) => order.name.includes('Sitch Band') || order.quantity > 15)) {
                  returnObj['size_x'] = 11;
                  returnObj['size_y'] = 9;
                  returnObj['size_z'] = 1;
                  returnObj['weight'] = 150;
                  if (paymentRecord.orderBreakdown.some((order) => order.name.includes('Sitch Band'))) {
                    returnObj['item_hs_code'] = '3926.20.40.50';
                  }
                }
                if (paymentRecord.orderBreakdown.some((order) => order.name.includes('Sitch Sticker') || order.name.includes('Sitch Bumper'))) {
                  returnObj['item_hs_code'] = '3919.90.50.60';
                }
                switch (returnObj['country_code']) {
                  case 'US':
                    returnObj['postage_type'] = 'chit_chats_us_edge';
                    break;
                  case 'CA':
                    returnObj['postage_type'] = 'chit_chats_select';
                    break;
                  default:
                    returnObj['postage_type'] = 'chit_chats_international_tracked';
                    break;
                }
                returnObj['item_country_of_origin'] = 'CA';
                returnObj['size_unit'] = 'in';
                returnObj['weight_unit'] = 'g';
                returnObj['package_type'] = 'thick_envelope';
                returnObj['insurance_requested'] = 'no';
              }
              returnObj['ship_date'] = 'today';

              return returnObj;
            });
          })
      );
    },
    exportCsv() {
      const csvOptions = this.csvOptions();
      const csvExporter = new ExportToCsv(csvOptions);

      csvExporter.generateCsv(
        this.paymentRecords
          .filter((rec) => {
            return !this.statusFilter || rec.orderStatus === this.statusFilter;
          })
          .map((paymentRecord) => {
            const returnObj: any = {};

            if (this.exportId) {
              returnObj[t.id] = paymentRecord.paymentIntentId;
            }
            if (this.exportReferenceId) {
              returnObj[t.referenceId] = paymentRecord.referenceId;
            }
            if (this.exportOrderStatus) {
              returnObj[t.status] = t[paymentRecord.orderStatus] || t.none;
            }
            if (this.exportFormattedBaseAmount) {
              returnObj[t.baseAmount] = paymentRecord.formattedBaseAmount;
            }
            if (this.exportFormattedTipAmount) {
              returnObj[t.tip] = paymentRecord.formattedTipAmount;
            }
            if (this.exportFormattedTaxAmount) {
              returnObj[t.tax] = paymentRecord.formattedTaxAmount;
            }
            if (this.exportFormattedTotalAmount) {
              returnObj[t.totalAmount] = paymentRecord.formattedTotalAmount;
            }
            if (this.exportDate) {
              returnObj[t.date] = format(paymentRecord.dateCreated.toDate(), 'yy-MM-dd h:mm a');
            }
            if (this.exportCustomersName) {
              returnObj[t.customersName] = paymentRecord.personWhoPaidName;
            }
            if (this.exportEmail) {
              returnObj[t.email] = paymentRecord.personWhoPaidEmail;
            }
            if (this.exportDeliveryAddress) {
              returnObj[t.address] = paymentRecord.deliveryAddress?.formattedAddress || t.none;
            }
            if (this.exportPromoCodesUsed) {
              returnObj[t.promoCodesUsed] = paymentRecord.promoCodes?.join(',') || t.none;
            }
            if (this.exportOrderBreakdown) {
              returnObj[t.order] =
                paymentRecord.orderBreakdown
                  ?.map((orderItem) => {
                    return `x${orderItem.quantity} ${orderItem.name}`;
                  })
                  .join(',') || t.none;
            }
            if (this.exportOrderLink) {
              returnObj[t.orderLink] = getOrderLink(paymentRecord);
            }

            return returnObj;
          })
      );
    },
    onClearDateRange() {
      this.dateRange = [];
      this.dateRangeMenu = false;
    },
    changeOrderTrackingLink(trackingLink: string) {
      this.updateOrder({
        trackingLink,
      }).catch((error: any) => {
        showError(`Could not update order status.`, error, true);
      });
    },
    changeOrderStatus(orderStatus: eOrderStatuses) {
      this.updateOrder({
        orderStatus,
      }).catch((error: any) => {
        showError(`Could not update order status.`, error, true);
      });
    },
    updateOrder(update: Partial<GlobalPaymentRecord>) {
      const currPayment = this.currPayment as GlobalPaymentRecord;
      return updateDoc(doc(currFirestore, getUserDoc().path, 'stripeBusinessPayments', currPayment.paymentIntentId), update).then(() => {
        showNotice(t.paymentRecordUpdated);
      });
    },
    enforceMinMax() {
      enforceMinMax(this, 'refundAmount', 100, this.maximumRefundAmount, this.currPayment?.currency);
    },
    showRefundDialogFunc() {
      const currPayment = this.currPayment as GlobalPaymentRecord;
      const amountAlreadyRefunded = currPayment.refundAmount || 0;
      this.maximumRefundAmount = currPayment.totalAmount - amountAlreadyRefunded || 0;
      this.refundAmount = this.maximumRefundAmount;
      this.showRefundDialog = true;
    },
    hideRefundDialogFunc() {
      this.maximumRefundAmount = 0;
      this.refundAmount = 0;
      this.showRefundDialog = false;
    },
    getFullAddress(deliveryAddress: DetailedAddress | null) {
      if (deliveryAddress) {
        return deliveryAddress.addressLine2 ? `${deliveryAddress.addressLine2}, ${deliveryAddress.formattedAddress}` : deliveryAddress.formattedAddress;
      }
      return '';
    },
    issueRefund() {
      const currPayment = this.currPayment as GlobalPaymentRecord;
      const userId = currPayment.platformUserId;
      const paymentIntentId = currPayment.paymentIntentId;

      if (this.refundAmount > this.maximumRefundAmount || this.refundAmount < 50) {
        showError(t.invalidRefundAmount.supplant([formatCurrency(100, currPayment.currency), formatCurrency(this.maximumRefundAmount, currPayment.currency)]));
        return;
      }

      if (!userId || !paymentIntentId) {
        showError(`Either or both the User ID and Payment Intent ID is missing.`);
        return;
      }

      // Issueing refunds is not currently possible for standard connected accounts but I'll keep this code around just in case it ever becomes a thing.
      showConfirmation(t?.refundConfirmationMessage.supplant([formatCurrency(this.refundAmount, currPayment.currency)]), () => {
        if (!this.stripeAccount) {
          showError(`Cannot issue refund for null Stripe account.`, null, true);
          return;
        }

        standardApiFetch(endpoints.createRefund, {
          paymentIntentId,
          userId,
          stripeUserId: this.stripeAccount.stripeUserId,
          refundAmount: this.refundAmount,
        })
          .then((response) => {
            if (response.successfulResponse.status === 'succeeded') {
              showNotice(t?.refundSuccessful);
              this.showPaymentDetailsDialog = false;
              this.showRefundDialog = false;
              this.currPayment = null;
            }
          })
          .catch(() => {
            showNotice(t?.refundUnsuccessful);
          });
      });
    },
    getPaymentsForSelectedAccount() {
      if (!this.$store.state.userId) {
        return;
      }

      if (this.watchedCollection) {
        this.watchedCollection(); // Detach old listenter if it exists
      }

      this.accountWasJustLoaded = true;
      this.totalRevenueBeforeTaxChargedForPeriod = {};
      this.totalRevenueAfterTaxChargedForPeriod = {};
      this.totalTaxChargedForPeriod = {};
      this.totalTipsForPeriod = {};
      this.totalsForToday = {};
      this.totalTipsForToday = {};
      this.totalsForToday = {};
      this.totalTipsForToday = {};
      this.periodSpecified = Boolean(this.dateRange.length);
      this.totalsForTodayExists = false;
      this.totalTipsForTodayExists = false;
      this.totalRevenueBeforeTaxChargedForPeriodExists = false;
      this.totalRevenueAfterTaxChargedForPeriodExists = false;
      this.totalTaxChargedForPeriodExists = false;
      this.totalTipsForPeriodExists = false;

      showLoading();

      const businessPaymentCollection = collection(currFirestore, getUserDoc().path, 'stripeBusinessPayments');

      let q = query(businessPaymentCollection, where('stripeUserId', '==', this.stripeAccount?.stripeUserId), orderBy('dateCreated', 'desc'), limit(100));

      if (this.periodSpecified) {
        const firstDateParts = this.dateRange[0].split('-').map((str) => Number(str));
        const secondDateParts = this.dateRange[1].split('-').map((str) => Number(str));
        const start = new Date(firstDateParts[0], firstDateParts[1] - 1, firstDateParts[2], 0, 0, 0);
        const end = new Date(secondDateParts[0], secondDateParts[1] - 1, secondDateParts[2], 23, 59, 59);
        q = query(
          businessPaymentCollection,
          where('stripeUserId', '==', this.stripeAccount?.stripeUserId),
          where('dateCreated', '>=', start),
          where('dateCreated', '<=', end),
          orderBy('dateCreated', 'desc')
        );
      }

      this.watchedCollection = onSnapshot(
        q,
        (querySnapshot) => {
          this.paymentRecords = [];

          if (this.accountWasJustLoaded) {
            // To stop the modal from popping up once a mode was just selected and the submissions are populating for the first time.
            Vue.nextTick(() => {
              this.accountWasJustLoaded = false;
            });
          }

          this.totalsForToday = {};
          this.totalTipsForToday = {};
          this.formattedTotalsForToday = {};
          this.formattedTotalTipsForToday = {};
          this.formattedTotalRevenueBeforeTaxChargedForPeriod = {};
          this.formattedTotalRevenueAfterTaxChargedForPeriod = {};
          this.formattedTotalTaxChargedForPeriod = {};
          this.formattedTotalTipsForPeriod = {};

          querySnapshot.forEach((doc) => {
            querySnapshot.docChanges().forEach((change) => {
              if (change.type === 'added') {
                const globalPaymentRecord = change.doc.data() as GlobalPaymentRecord;
                this.mostRecentPayment = globalPaymentRecord;

                let shouldShowAlert = this.$store.state.currUser.settings.showNewPaymentAlert;

                if (shouldShowAlert && this.mostRecentPayment && !this.accountWasJustLoaded) {
                  this.showPaymentDetailsDialogFunc(this.mostRecentPayment);
                  this.$store.commit('currAlertObject', {
                    message: t.newPayment,
                  });
                }
              }
            });

            const globalPaymentRecord = doc.data() as GlobalPaymentRecord;
            this.paymentRecords.push(globalPaymentRecord);
          });

          const paymentsMadeToday = this.paymentRecords.filter((payment) => {
            return format(payment.dateCreated.toDate(), 'yy-MM-dd') === format(new Date(), 'yy-MM-dd');
          });

          paymentsMadeToday.forEach((payment: GlobalPaymentRecord) => {
            const currency = payment.currency.toUpperCase() || '???';
            // Init this payments currency to 0 if it doesn't exist in the objects yet. This way we don't try to add 0 to undefined to get NaN when we increment the totals.
            this.totalsForToday[currency] = this.totalsForToday[currency] || 0;
            this.totalTipsForToday[currency] = this.totalTipsForToday[currency] || 0;
            // Increment totals.
            this.totalsForToday[currency] += payment.totalAmount || 0;
            this.totalTipsForToday[currency] += payment.tipAmount || 0;
          });

          function sumValueExists(sumObj: any): boolean {
            const valArray: number[] = Object.values(sumObj);
            return Boolean(valArray.length) && valArray.reduce((prev: number, curr: number) => prev + curr) !== 0;
          }

          this.totalsForTodayExists = sumValueExists(this.totalsForToday);
          this.totalTipsForTodayExists = sumValueExists(this.totalTipsForToday);

          Object.entries(this.totalsForToday).forEach(([currencyCode, amount]) => {
            this.formattedTotalsForToday[currencyCode] = formatCurrency(amount, currencyCode, false, true);
          });
          Object.entries(this.totalTipsForToday).forEach(([currencyCode, amount]) => {
            this.formattedTotalTipsForToday[currencyCode] = formatCurrency(amount, currencyCode, false, true);
          });

          if (this.periodSpecified) {
            this.paymentRecords.forEach((payment: GlobalPaymentRecord) => {
              // Init this payments currency to 0 if it doesn't exist in the objects yet. This way we don't try to add 0 to undefined to get NaN when we increment the totals.
              this.totalRevenueBeforeTaxChargedForPeriod[payment.currency] = this.totalRevenueBeforeTaxChargedForPeriod[payment.currency] || 0;
              this.totalRevenueAfterTaxChargedForPeriod[payment.currency] = this.totalRevenueAfterTaxChargedForPeriod[payment.currency] || 0;
              this.totalTaxChargedForPeriod[payment.currency] = this.totalTaxChargedForPeriod[payment.currency] || 0;
              this.totalTipsForPeriod[payment.currency] = this.totalTipsForPeriod[payment.currency] || 0;
              // Increment totals.
              this.totalRevenueBeforeTaxChargedForPeriod[payment.currency] += payment.baseAmount || 0;
              this.totalRevenueAfterTaxChargedForPeriod[payment.currency] += payment.totalAmount || 0;
              this.totalTaxChargedForPeriod[payment.currency] += payment.taxAmount || 0;
              this.totalTipsForPeriod[payment.currency] += payment.tipAmount || 0;
            });
          }

          this.totalRevenueBeforeTaxChargedForPeriodExists = sumValueExists(this.totalRevenueBeforeTaxChargedForPeriod);
          this.totalRevenueAfterTaxChargedForPeriodExists = sumValueExists(this.totalRevenueAfterTaxChargedForPeriod);
          this.totalTaxChargedForPeriodExists = sumValueExists(this.totalTaxChargedForPeriod);
          this.totalTipsForPeriodExists = sumValueExists(this.totalTipsForPeriod);

          // Format the ammounts.
          Object.entries(this.totalRevenueBeforeTaxChargedForPeriod).forEach(([currencyCode, amount]) => {
            this.formattedTotalRevenueBeforeTaxChargedForPeriod[currencyCode] = formatCurrency(amount, currencyCode, false, true);
          });
          Object.entries(this.totalRevenueAfterTaxChargedForPeriod).forEach(([currencyCode, amount]) => {
            this.formattedTotalRevenueAfterTaxChargedForPeriod[currencyCode] = formatCurrency(amount, currencyCode, false, true);
          });
          Object.entries(this.totalTaxChargedForPeriod).forEach(([currencyCode, amount]) => {
            this.formattedTotalTaxChargedForPeriod[currencyCode] = formatCurrency(amount, currencyCode, false, true);
          });
          Object.entries(this.totalTipsForPeriod).forEach(([currencyCode, amount]) => {
            this.formattedTotalTipsForPeriod[currencyCode] = formatCurrency(amount, currencyCode, false, true);
          });

          const paymentsForLastFiveDays: any = {};
          const tipsForLastFiveDays: any = {};
          const totalPayments: any = {};
          const dates: string[] = [];

          for (let d = 0; d < 5; d++) {
            const currDate = new Date();
            currDate.setDate(currDate.getDate() - d);
            const dateString = format(currDate, 'yy-MM-dd');
            dates.push(dateString);

            const paymentsForCurrentDate = this.paymentRecords.filter((payment) => {
              return format(payment.dateCreated.toDate(), 'yy-MM-dd') === dateString;
            });

            paymentsForCurrentDate.forEach((payment: GlobalPaymentRecord) => {
              const currency = payment.currency.toUpperCase() || '???';
              const divisor = ZERO_DECIMAL_CURRENCIES.includes(currency) ? 1 : 100;
              paymentsForLastFiveDays[currency] = paymentsForLastFiveDays[currency] || {};
              tipsForLastFiveDays[currency] = tipsForLastFiveDays[currency] || {};
              paymentsForLastFiveDays[currency][dateString] = paymentsForLastFiveDays[currency][dateString] || 0;
              tipsForLastFiveDays[currency][dateString] = tipsForLastFiveDays[currency][dateString] || 0;
              paymentsForLastFiveDays[currency][dateString] += Number(payment.totalAmount / divisor || 0);
              tipsForLastFiveDays[currency][dateString] += Number(payment.tipAmount / divisor || 0);
            });

            totalPayments[dateString] = paymentsForCurrentDate.length;
          }

          dates.reverse();

          const paymentsDatasets = (Object.entries(paymentsForLastFiveDays) as Array<[string, { [date: string]: number }]>).map(([currencyCode, dateAmountMap]) => {
            const values = [];

            // Fill empty dates and push into sorted array.
            for (let d = 0; d < 5; d++) {
              const currDate = new Date();
              currDate.setDate(currDate.getDate() - d);
              const dateString = format(currDate, 'yy-MM-dd');
              values.push(dateAmountMap[dateString] || 0);
            }

            return {
              label: t?.paymentsForCurrencyChart.supplant([currencyCode]),
              data: values.reverse(),
              backgroundColor: ['rgba(255, 139, 30, 0.2)'],
              borderColor: ['rgba(255, 139, 30, 1)'],
              borderWidth: 1,
            };
          });

          const tipDatasets = (Object.entries(tipsForLastFiveDays) as Array<[string, { [date: string]: number }]>).map(([currencyCode, dateAmountMap]) => {
            const values = [];

            // Fill empty dates and push into sorted array.
            for (let d = 0; d < 5; d++) {
              const currDate = new Date();
              currDate.setDate(currDate.getDate() - d);
              const dateString = format(currDate, 'yy-MM-dd');
              values.push(dateAmountMap[dateString] || 0);
            }

            return {
              label: t?.tipsForCurrencyChart.supplant([currencyCode]),
              data: values.reverse(),
              backgroundColor: ['rgba(255, 99, 132, 0.2)'],
              borderColor: ['rgba(255, 99, 132, 1)'],
              borderWidth: 1,
            };
          });

          const paymentsChart: HTMLCanvasElement = this.$refs.paymentsChart as HTMLCanvasElement;
          new Chart(paymentsChart, {
            type: 'line',
            data: {
              labels: dates,
              datasets: [...paymentsDatasets, ...tipDatasets],
            },
            options: {
              scales: {
                yAxes: [
                  {
                    ticks: {
                      beginAtZero: true,
                    },
                  },
                ],
              },
            },
          });
          const totalPaidForOrdersChart: HTMLCanvasElement = this.$refs.totalPaidForOrdersChart as HTMLCanvasElement;
          new Chart(totalPaidForOrdersChart, {
            type: 'line',
            data: {
              labels: dates,
              datasets: [
                {
                  label: t?.numberOfPayments,
                  data: Object.values(totalPayments).reverse() as number[],
                  backgroundColor: ['rgba(55, 99, 132, 0.2)'],
                  borderColor: ['rgba(55, 209, 132, 1)'],
                  borderWidth: 1,
                },
              ],
            },
            options: {
              scales: {
                yAxes: [
                  {
                    ticks: {
                      beginAtZero: true,
                    },
                  },
                ],
              },
            },
          });
          hideLoading();
        },
        (error) => {
          showError(`Could not retrieve payments.`, error, true);
          hideLoading();
        }
      );
    },
    showPaymentDetailsDialogFunc(currPayment: GlobalPaymentRecord) {
      this.showPaymentDetailsDialog = true;
      this.currPayment = {

        ...getEmptyGlobalPaymentRecord(),
        ...currPayment,
        trackingLink: currPayment.trackingLink || this.stripeAccount?.displayName === 'sitch.cards' ? 'https://chitchats.com/tracking/' : '',
      };
    },
    hidePaymentDetailsDialogFunc() {
      this.showPaymentDetailsDialog = false;
    },
    formatCurrency(price: number, currency: string): string {
      return formatCurrency(price, currency);
    },
    formatDate(date: Date) {
      return format(date, 'MM/dd/yyyy, h:mm a');
    },
    isRecent(dateInMs: number): boolean {
      return Date.now() - dateInMs < 300_000; // 5 minutes.
    },
  },
});
